.main-page-header {

    @include tablet-md {
        @include flProperty("padding-top", 220px, 255px, 768px, 991px, false);
    }
    @include mobile-all {
        @include flProperty("padding-top", 146px, 218px, 320px, 768px, false);
    }

    @for $i from 0 through 100 {
        &.fixed-percent-#{$i} {
            .main-page-nav {
                @if $i == 0 {
                    @include tablet-md {
                        .page-logo-wrapper {
                            grid-row: 2;

                            .main-page-logo {
                                padding-top: 16.02%;
                            }
                        }
                    }
                } @else {
                    @include tablet-md {
                        .page-logo-wrapper {
                            grid-row: 1;

                            .main-page-logo {
                                padding-top: 16.02% - ($i * 7.52% / 100);
                            }
                        }
                    }
                }
            }
        }
    }

}

.main-page-nav.container {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: max-content max-content max-content;
    padding-top: 82px;
    margin-bottom: 45px;
    padding-bottom: 30px;
    position: relative;
    border-bottom: 1px solid $bolero-gray;

    @include tablet-md-to {
        border-bottom: none;
        padding-top: 14px;
        grid-template-columns: auto 1fr auto;
        margin-bottom: 30px;
        position: fixed;
        top: 0;
        z-index: 9999;
        background-color: white;
        transition: padding-top 0.3s ease-in-out;
    }

    .menu-buttons-wrapper {
        display: none;
    }

    .menu-hamburger {
        display: none;
        @include tablet-md-to {
            z-index: 9999;
            position: fixed;
            bottom: 25px;
            left: 15px;
            right: 15px;
            width: auto;
            min-height: 58px;
            background: linear-gradient(96.16deg, #9743F4 -34.9%, #FF682A 57.3%, #EE0016 173.87%);
            box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.3);
            border-radius: 4px;
            display: block;
        }

        &.menu-hamburger-left {
            @include tablet-md-to {
                z-index: 10000;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                box-shadow: none;
            }
        }

        &:not(.menu-hamburger-left) {
            @include tablet-md-to {
                height: auto;
                padding-bottom: 58px;
                max-height: 0;
                overflow: hidden;
                transition: max-height 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
            }
        }

        &.expanded {
            max-height: max-content;
        }

        .main-page-logo {
            display: none;
        }

        .hamburger-list-items {
            text-align: center;
            list-style: none;
            padding: 0;
            margin: 0;

            .menu-item-mobile {
                .nav-menu-title {
                    color: $white;
                    font-family: $relative-book-font;
                    font-size: 22px;
                    line-height: 1;
                    letter-spacing: 1px;
                    text-decoration: none;
                    text-transform: uppercase;
                    display: block;
                    padding: 12px 0;
                }
            }
        }

        .menuToggle {
            position: relative;
            z-index: 0;
            user-select: none;
            cursor: pointer;
            width: 100%;
            height: 58px;
            display: inline-flex;

            &:after {
                content: 'menu';
                text-transform: uppercase;
                font-family: $relative-book-font;
                font-size: 20px;
                line-height: 1.15;
                letter-spacing: 1px;
                color: $white;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(calc(-13%), calc(-50% - 1px));
                opacity: 1;
                transition: opacity 0.2s ease-in-out;
            }

            span {
                display: block;
                width: 28px;
                height: 1px;
                margin-bottom: 5px;
                position: absolute;
                background: $white;
                z-index: 1;
                transform-origin: 4px 0;
                transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
                background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
                opacity 0.55s ease;
                @include standard-animation-mix();
                transform: translate(-150%, -2px);
            }

            span:first-child {
                transform-origin: 50% 50%;
                top: calc(50% - 6px);
                left: 50%;
            }

            span:nth-last-child(2) {
                top: 50%;
                left: 50%;
                opacity: 1;
            }

            span:nth-last-child(1) {
                margin-bottom: 0;
                left: 50%;
                top: calc(50% + 6px);
                transform-origin: 50% 50%;
            }

            &.expanded {
                span {
                    transform: translate(-50%, -2px);
                }

                span:nth-last-child(1) {
                    top: calc(50%);
                    transform: translate(-50%, -2px) rotate(-45deg);
                }

                span:nth-last-child(2) {
                    opacity: 0;
                }

                span:nth-last-child(3) {
                    top: calc(50%);
                    transform: translate(-50%, -2px) rotate(45deg);
                }

                &:after {
                    opacity: 0;
                }
            }
        }
    }

    &:before {
        content: '';
        display: block;
        grid-column: 1;
        grid-row: 2/4;
    }

    .headerLanguages {
        grid-column: 1;
        grid-row: 1;
        display: inline-flex;
        align-items: center;

        .ring-simple-menu--listWrapper {
            display: inline-flex;
            font-family: $relative-book-font;
            font-size: 18px;
            line-height: 1.16;
            letter-spacing: 1px;
            text-transform: uppercase;

            @include tablet-md-to {
                font-size: 16px;
            }

            &:not(:last-child) {
                &:after {
                    content: '/';
                    display: inline-block;
                    opacity: 0.3;
                    margin: 0 6px;
                }
            }

            .ring-simple-menu--labelLink {
                color: $black;
                opacity: 1;
                text-decoration: none;

                &:hover, &:focus {
                    opacity: 0.3;
                    text-decoration: underline;
                }
            }
        }
    }

    .page-logo-wrapper {
        grid-column: 2;
        grid-row: 1;
        @media (min-width: 992px) {
        width: 430px;
        justify-self: center;
        align-self: center;
        }

        @include tablet-md-to {
            grid-column: 1/4;
            grid-row: 2;
            padding-top: 10px;
        }

        .main-page-logo {
            width: 100%;
            padding-top: 16.02%;
            display: block;
            position: relative;
            overflow: hidden;

            @include tablet-md-to {
                transition: padding-top 0.2s ease-in-out;
                max-width: 430px;
                margin: 0 auto;
            }

            .image-wrapper {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;

                img {
                    display: block;
                    margin: 0 auto;
                }
            }
        }
    }

    .headerText {
        grid-column: 2;
        grid-row: 2;
        text-align: center;
        font-size: 16px;
        line-height: 1.3;
        padding: 23px 20px 0;

        @include ringHeaders {
            margin: 0;
        }

        @include tablet-md-to {
            display: none;
        }
    }

    .menu-desktop {
        grid-column: 2;
        grid-row: 3;
        padding: 0;
        margin: 20px 0;
        display: flex;
        align-items: center;
        align-content: center;
        list-style: none;
        gap: 2rem;

        @include tablet-md-to {
            display: none;
        }

        .menu-item {
            flex: 1 1 auto;
            text-align: center;

            .menu-title {
                font-family: $relative-book-font;
                font-size: 22px;
                line-height: 1.13;
                letter-spacing: 1px;
                text-align: center;
                text-transform: uppercase;
                text-decoration: none;
                color: $black;
                position: relative;
            }

            &.active-menu-element {
                .menu-title {
                    &:after {
                        position: absolute;
                        content: '';
                        display: block;
                        width: calc(100% + 27px);
                        height: 6px;
                        background: $white;
                        left: 50%;
                        bottom: -50px;
                        transform: translate(-50%, 0);
                        z-index: 1;
                    }
                }
            }

            @each $name, $value in $boleroColors {
                &.categoryColor#{$name} {
                    .menu-title {
                        color: $value;
                    }

                    &.active-menu-element {
                        .menu-title {
                            &:after {
                                background: $value;
                            }
                        }
                    }
                }
            }
        }
    }

    .headerSocials {
        grid-column: 3;
        grid-row: 1;
        justify-self: end;
        align-items: center;
        display: flex;

        .ring-simple-menu--listWrapper {
            display: inline-flex;

            .ring-simple-menu--labelLink {
                font-size: 0;
                display: flex;
                align-items: center;
                width: auto;
                height: auto;

                &:before {
                    display: inline-block;
                    font-family: $relative-book-font;
                    font-size: 1rem;
                    text-transform: uppercase;
                    color: $black;
                    margin-right: 6px;
                    position: relative;
                }

                &:after {
                    content: '';
                    display: inline-block;
                    width: 22px;
                    height: 22px;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: contain;
                }

                @include tablet-md-to {
                    &:before {
                        display: none;
                    }
                    &:after {
                        width: 16px;
                        height: 16px;
                    }
                }
            }

            &:nth-child(1) .ring-simple-menu--labelLink {
                &:before {
                    content: 'Newsletter';
                }
                &:after {
                    background-image: url('https://ocdn.eu/ringier-ag-verticals/bolero/assets/BoleroNewsletter.svg');
                }
            }

            &:nth-child(2) .ring-simple-menu--labelLink {
                &:after {
                    background-image: url('../img/instagram.svg');
                }
            }

            &:nth-child(3) .ring-simple-menu--labelLink {
                &:after {
                    background-image: url('../img/fb.svg');
                }
            }

            &:not(:first-child) {
                margin-left: 12px;

                @include tablet-md-to {
                    margin-left: 18px;
                }
            }
        }
    }

    &:after {
        content: '';
        display: block;
        grid-column: 3;
        grid-row: 2/4;

        @include tablet-md-to {
            grid-column: none;
            grid-row: none;
            height: 1px;
            left: 20px;
            right: 20px;
            bottom: 0;
            top: auto;
            position: absolute;
            background-color: $bolero-gray;
        }
    }
}

.articleDetail {
    @include tablet-lg-from {

        .main-page-nav.container {
            grid-template-columns: 197px 1fr auto;
            grid-template-rows: max-content;
            padding-top: 16px;
            margin-bottom: 44px;
            padding-bottom: 21px;
            border: none;

            .headerText, .headerSocials, .headerLanguages {
                display: none;
            }

            .page-logo-wrapper {
                grid-column: 1;
                grid-row: 1;
                align-self: center;
                width: auto;
                justify-self: auto;
            }

            .menu-desktop {
                grid-column: 3;
                grid-row: 1;
                margin: 20px -21px;
                gap: 0;

                .menu-item {
                    padding: 0 21px;

                    .menu-title {
                        font-size: 18px;
                        line-height: 1.15;
                    }

                    &.active-menu-element {
                        .menu-title {
                            &:after {
                                bottom: -42px;
                            }
                        }
                    }
                }
            }

            &:before {
                content: '';
                display: block;
                grid-column: 2;
                grid-row: 1;
            }

            &:after {
                display: block;
                grid-column: none;
                grid-row: none;
                height: 1px;
                left: 15px;
                right: 15px;
                bottom: 0;
                top: auto;
                position: absolute;
                background-color: $bolero-gray;
            }
        }
    }
}
